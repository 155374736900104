<template>
  <v-card class="mx-auto">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${loading ? '' : `(${table_data.length})`}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right"> </v-col>
        <v-col v-if="table_data.length > 0" cols="12" xs="12" md="12">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="loading"
            dense
          >
            <template v-slot:item.key="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.url_video="{ item }">
              <v-btn
                v-if="item.url_video"
                icon
                small
                color="success"
                :href="item.url_video"
                target="_blank"
              >
                <v-icon> mdi-video-check</v-icon>
              </v-btn>
              <v-icon small v-else> mdi-video-off </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="primary"
                    :to="{
                      name: 'reports.' + item.route,
                    }"
                  >
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      page_route: "faqs",
      login: this.$store.getters.getLogin,
      loading: false,
      table_data: [
        {
          id: 1,
          name: "GENERALES",
          route: "generals",
        },
        {
          id: 2,
          name: "UNIFICADO",
          route: "insurances",
        },
      ],
      table_search: "",
      table_headers: [
        {
          text: "#",
          filterable: true,
          value: "key",
          width: "60px",
        },
        {
          text: "Reporte",
          filterable: true,
          value: "name",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "60px",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>